<template>
  <div class="document-item">
    <span>
      <RemixIconComponent category="document" name="file-list-line" />
    </span>
    <h6>{{ data.title }}</h6>
    <a :href="data.link" target="_blank" class="btn btn--share btn--md">
      Открыть
      <RemixIconComponent category="system" name="arrow-right-line" />
    </a>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
export default {
  name: "DocumentItemComponent",
  components: { RemixIconComponent },
  props: {
    data: Object,
  },
};
</script>

<style lang="stylus">
.document-item{
  display flex
  flex-direction column
  align-items: flex-start;
  justify-content: space-between;
  padding 32px
  gap 16px
  background: var(--white);
  border: 1px solid var(--border-color-2);
  border-radius: 16px;
  height: 222px;
  span{
    display flex
    align-items center
    justify-content center
    width: 32px;
    height: 32px;
    background: var(--gray-100);
    border-radius: 8px;
    white-space: break-spaces;
    svg{
      width: 16px;
      height: 16px;
    }
  }
  h6{
    text-transform none
    margin 0
    font-weight: 500;
    font-size: 1.125em;
    line-height: 24px;
    color: var(--black);
    white-space break-spaces
  }
}
</style>
