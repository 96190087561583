<template>
  <main class="documents-page page">
    <div class="page__inner">
      <SectionComponent>
        <h1>Документы</h1>
        <div class="documents--grid">
          <DocumentItemComponent v-for="(item, i) in documents" :key="i" :data="item" />
        </div>
      </SectionComponent>
    </div>
  </main>
</template>

<script>
import SectionComponent from "components/SectionComponent.vue";
import DocumentItemComponent from "./components/DocumentItemComponent.vue";
export default {
  name: "index",
  data() {
    return {
      documents: [
        { title: "Политика\n" + " приватности", link: "" },
        { title: "Условия\n" + " использования", link: "" },
        { title: "Публичная\n" + "оферта", link: "" },
        { title: "Название\n" + "документа 1", link: "" },
        { title: "Название\n" + "документа 2", link: "" },
        { title: "Название\n" + "документа 3", link: "" },
      ],
    };
  },
  components: { DocumentItemComponent, SectionComponent },
  metaInfo: {
    titleTemplate: "%s | Зеленое яблоко",
    metaInfo: {
      title: "Документы",
    },
  },
};
</script>

<style lang="stylus">
.documents-page{
  .section-inner{
    gap 0
  }
  .documents--grid{
    display grid
    grid-template-columns repeat(4, 1fr)
    gap 24px
  }
}
</style>
